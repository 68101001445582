.enregistrement-seance {
  .formulaire {
    padding: 60px 0;
  }
  .icon {
    color: #144073;
  }
  p {
    font-size: 1.1em;
    margin-bottom: 60px;
  }
}
