nav {
    .navbar-brand img {
        height: 150px;
        transition: all 0.3s ease-out;
        @include media-breakpoint-down(md) {
            height: 100px;
        }
    }
    &.scroll {
        box-shadow: 0px 3px 7px 0px rgba(1, 64, 119, 0.3);
        background-color: #fff;
        .navbar-brand img {
            height: 60px !important;
        }
    }
    .dropdown {
        cursor: default;
        .dropdown-menu {
            top: 95% !important;
        }
        &:hover,
        &:hover .dropdown-menu {
            display: block;
        }
        .dropdown-menu .nav-link.active {
            color: $bleu;
            border-bottom: none;
        }
    }
    .nav-item {
        font-size: 1.1em;
        margin: 0 10px;
        white-space: nowrap;
        .nav-link.active {
            color: $bleu !important;
            font-weight: 500;
            border-bottom: $bleu 5px solid;
        }
    }
    .navbar-collapse.show, .collapsing {
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 3px 7px 0px rgba(1, 64, 119, 0.3);
    }
    &.scroll .navbar-collapse.show {
        box-shadow: none !important; 
    }
}
