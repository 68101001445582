#galerie-photos {
  padding: 60px 0;
  .liste-photos {
    display: grid;
    column-gap: 6px;
    grid-template-columns: repeat(5, auto);
    img {
      max-width: 500px;
      width: 100% !important;
      height: 200px !important;
      object-fit: cover;
      margin: 3px;
    }
    @include media-breakpoint-down(lg) {
      grid-template-columns: repeat(4, auto);
    }
    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(3, auto);
    }
    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(2, auto);
    }
    @include media-breakpoint-down(xs) {
      grid-template-columns: repeat(1, auto);
    }
  }
}
