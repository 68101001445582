.home-presentation {
    margin-top: 80px;
    margin-bottom: 80px;
    .contenu {
        p {
            text-align: justify;
        }
        h2 {
            display: inline-block;
            div {
                display: block;
                height: 6px;
                width: 50%;
                margin: 20px 0;
            }
        }
    }
    .img-simple {
        margin: 0 0 0 60px;
        min-width: 500px;
        height: 400px;
        background-size: cover;
        background-position: center;
    }
    #carousel-cteca,
    .img-simple {
        @include media-breakpoint-down(md) {
            margin: 0 !important;
            display: none;
        }
    }
    #carousel-cteca {
        width: 500px;
        margin: 0 0 0 60px;
        .carousel-inner {
            .carousel-item {
                width: 500px;
                height: 400px;
                background-size: cover;
                background-position: center;
            }
        }
    }
    img,
    #carousel-cteca {
        @include media-breakpoint-down(md) {
            display: none;
        }
    }
}
