#header {
  .banniere {
    height:350px;
    background-size: cover;
    background-position: center;
  }
  .contenu {
    @include media-breakpoint-down(sm) {
        font-size: 1.4em;
    }
  }
}
