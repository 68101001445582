.contactez-nous {
  .formulaire {
    padding: 60px 0;
  }
  .icon {
    color: #144073;
  }
  p {
    font-size: 1.1em;
  }
  a {
    text-decoration: none;
  }
  a, .liste-contact {
    color: $gris;
  }
}
