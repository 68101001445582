.actualites.accueil {
  padding: 60px 0;
  background-color: $gray-100;
}
.carte-actualite {
  background-color: transparent !important;
  &.card {
    text-decoration: none;
    color: $gris;
    .card-content {
      position: relative;
      .card-text > * {
        font-size: 1em !important; 
        margin: 0;
      }
      cursor: pointer;
      &:after {
        content: "En savoir plus";
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0;
        transition: all 0.3s;
        color: #fff;
        background-color: rgba($bleu, 0.9);
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
    .card-content:hover:after {
      opacity: 1;
    }
    .card-img-top {
      height: 200px;
      background-size: cover;
      background-position: center;
    }
    .card-title {
      color: $bleu;
      font-size: 1.5em;
    }
  }
}
