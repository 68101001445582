.home-header {
    height: 100vh;
    background-size: cover;
    position: relative;
    img {
        width: 700px;
        max-width: 100%;
        padding: 0 20px;
    }
    .contenu {
        font-size: 1.7em;
        max-width: 800px;
        @include media-breakpoint-down(sm) {
            font-size: 1.2em;
        }
    }
    .information {
        position: absolute;
        width: 100%;
        bottom: 0;
        p:last-child {
            margin: 0;
        }
        & * {
            color: #fff;
        }
    }
}
