.liste-page-pdf {
  .page-pdf {
    padding: 60px 0;
    &:nth-child(even) {
      background-color: #F7F7F7;
    }
    .page-pdf-wrapper {
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      .item {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
