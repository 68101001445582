@font-face {
  font-family: "Font Awesome 5 Free";
  src: url("../fonts/webfonts/fa-solid-900.woff2") format("woff2"),
    url("../fonts/webfonts/fa-solid-900.woff") format("woff");
}

html {
  scroll-behavior: smooth;
}

body {
  color: $bleu-dark !important;
}

:target:before {
  content: "";
  display: block;
  height: 70px;
  margin-top: -70px;
}
p {
  line-height: 170% !important;
  color: $gris;
}
.texte-bleu-dark * {
  color: $bleu-dark !important;
}
.texte-bleu * {
  color: $bleu !important;
}
.texte-rouge * {
  color: $bleu-dark !important;
}
.texte-rouge * {
  color: $rouge !important;
}
.texte-jaune * {
  color: $jaune !important;
}

.liste-cteca {
  li {
    list-style: none;
    text-align: justify;
    margin-bottom: 20px;
    &:before {
      content: "";
      margin-right: 10px;
      display: inline-block;
      height: 15px;
      width: 15px;
      background-image: url("../images/icon/bone.svg");
      background-size: contain;
    }
  }
}

// PAGE DEFAULT
.default {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 50px;
    margin-bottom: 20px;
  }
}

// ----- BOUTONS GALERIE ----- //
.lg {
  .lg-icon {
    font-family: "Font Awesome 5 Free" !important;
    font-weight: 900 !important;
  }
  .lg-actions {
    .lg-prev:after {
      content: "\f060" !important;
    }
    .lg-next:before {
      content: "\f061" !important;
    }
  }
  .lg-toolbar {
    .lg-download:after {
      content: "\f019" !important;
    }
    .lg-close:after {
      content: "\f00d" !important;
    }
  }
}
