.page-cteca {
  .paragraphe {
    &:nth-child(3n + 1) .icon-color {
      color: $bleu;
    }
    &:nth-child(3n + 2) .icon-color {
      color: $rouge;
    }
    &:nth-child(3n + 3) .icon-color {
      color: $jaune;
    }
    padding: 80px 0;
    .contenu {
      p {
        text-align: justify;
      }
      h2 {
        display: inline-block;
        div {
          display: block;
          height: 6px;
          width: 50%;
          margin: 20px 0;
        }
      }
    }
    .img-simple {
      margin: 0 0 0 60px;
      width: 500px;
      height: 400px;
      background-size: cover;
      background-position: center;
    }
    &:nth-child(even) {
      background-color: #F7F7F7;
      .order{
        order: -1;
      }
      #carousel-cteca,
      .img-simple {
        order: -1;
        margin: 0 60px 0 0 !important;
      }
    }
    #carousel-cteca,
    .img-simple {
      @include media-breakpoint-down(md) {
        margin: 0 !important;
        display: none;
      }
    }
    #carousel-cteca {
      width: 500px;
      margin: 0 0 0 60px;
      .carousel-inner {
        .carousel-item {
          width: 500px;
          height: 400px;
          background-size: cover;
          background-position: center;
        }
      }
    }
    img,
    #carousel-cteca {
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
