.page-actualite {
    margin-top: 60px;
    margin-bottom: 60px;
    .contenu {
        p {
            text-align: justify;
        }
        h2 {
            display: inline-block;
            div {
                display: block;
                height: 6px;
                width: 50%;
                margin: 20px 0;
            }
        }
    }
    .liste-photos {
        display: grid;
        column-gap: 6px;
        grid-template-columns: repeat(3, auto);
        img {
            max-width: 500px;
            width: 100% !important;
            height: 300px !important;
            object-fit: cover;
            margin: 3px;
        }
        @include media-breakpoint-down(md) {
            grid-template-columns: repeat(2, auto);
        }
        @include media-breakpoint-down(sm) {
            grid-template-columns: repeat(1, auto);
        }
    }
}
