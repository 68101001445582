.texte-pdf {
    padding: 40px 0;
    .texte {
        padding-right: 30px;
        @include media-breakpoint-down(lg) {
            padding-right: 0px;
        }
    }
    .contenu {
        h1, h2, h3, h4, h5, h6 {
            margin: 30px 0;
        }
    }
    .liste-pdf {
        background-color: $gray-100;
        h2 {
            font-size: 1.3em;
        }
        ul {
            list-style: none;
        }
    }
    p {
        text-align: justify;
    }
}